<template>
<div id="navigation-wrap">
    <div id="nav-button">
        <button name="menu" type="default" plain @click="onClick">
        <lottie 
            :options="defaultOptions"
            :height="75"
            :width="75"
            v-on:animCreated="handleAnimation"/>
        </button>
    </div>
    <div id="nav-menu" ref="navmenu">
        <ul class="nav-common">
            <li><router-link to="/">Home</router-link></li>
            <li><router-link to="/about">About</router-link></li>
            <li><router-link to="/portfolio">Portfolio</router-link></li>
            <li><router-link to="/case-study">Case Study</router-link></li>
            <li><router-link to="/contact">Contact</router-link></li>
        </ul>
    </div>
</div>
</template>

<script>
import Lottie from 'vue-lottie';
import animationData from '../assets/json/navmenu_icon_v3.json';

export default {
    name: "Navigation",
    components: { 
        Lottie,
    },
    data() {
      return {
        defaultOptions: {animationData: animationData, loop:false, autoplay: false},
        animationSpeed: 1,
        open: false,
        canClick: true
      }
    },
    methods: {
        handleAnimation (anim) {
            this.anim = anim;
        },
        onClick(){
            if (this.canClick) {
                this.canClick = false;
                if (this.open) {
                    this.closeNavbar();
                } else {
                    this.openNavbar();
                }
            }
        this.canClick = true;
      },
      openNavbar() {
        this.anim.playSegments([9, 28], true);
        this.$refs.navmenu.style.transform = "translateX(0px)";
        this.open = true;
      },
      closeNavbar() {
        this.anim.playSegments([35, 51], true);
        this.$refs.navmenu.style.transform = "translateX(100%)";
        this.open = false;
      }
    },
    watch: {
        $route (_, from){
            if ((from.path != "/") && (this.open)) {
                this.closeNavbar();
            }
        }
    }
}
</script>

<style lang="scss">

#navigation-wrap {
    position: fixed;
    width: 100vw;
    z-index: 8;
}

#nav-button {

    position: fixed;
    top: 0px;
    right: 0.5em;
    z-index: 9;

    @include respond-to('small') {
    right: 0;
    }

    button {
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        margin: 1.5em;
        transform: none;
        transition: transform 0.2s;

        @include respond-to('small') {
        background-color: $transparent_offblack;
        margin: 1em 0;
        }

        &:hover {
            transform: scale(1.1);
            transition: transform 0.2s;
        }
    }
}

#nav-menu {
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 8;
    background-color: $transparent_offblack;
    height: 100vh;
    width: 15%;
    min-width: 225px;
    transform: translateX(100%);
    transition: transform 0.3s;

    @include respond-to('medium') {
        min-width: 300px;
    }

    @include respond-to('medium') {
        background-color: $offblack;
    }

    .nav-common {
        margin-top: 12vh;
        padding-left: 4em;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: left;

        @include respond-to('small') {
        padding-left: 4em;
        margin-top: 20vh;
        }

        li {
            height: 4em;
            text-align: left;

            a {
                font-size: 2em;
                text-decoration: none;
                color: $medium;
                cursor: pointer;
                transition: all 0.25s;
                position: relative;

                @include respond-to('medium') {
                    color: $light;
                }

                &::before {
                    content: "//";
                    font-size: 1.2em;
                    position: absolute;
                    left: -0.75em;
                    top: -0.1em;
                    pointer-events: none;
                }

                &::after {
                    content: "";
                    font-size: 1.2em;
                    pointer-events: none;
                }

                &:hover {
                    color:white;
                    transition: all 0.25s;

                    &::before {
                        content: "{ ";
                        left: -0.5em;
                        top: 0px;
                    }

                    &::after {
                        content: " }";
                    }
                }
            }
        }
    }
}

.router-link-active {
    color: $accent !important;
}

</style>