<template>
  <animation />
  <navigation class="navDefault" v-bind:class="{ inActive: isHome }" />
  <div id="wrapper">
    <router-view v-slot="{ Component, route }">
      <!-- Use any custom transition and fallback to `fade` -->
      <transition :name="route.meta.transition || 'page'" mode="out-in">
          <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
import Animation from "./components/Animation.vue"
import Navigation from "./components/Navigation.vue"

export default {
  name: "App",
  components: {
    Animation,
    Navigation,
  },
  data() {
    return {
      
    };
  },
  methods: {

  },
  computed: {
    isHome() {
      return (this.$route.name === 'Home')
    }
  },
  mounted() {
    
  },
};
</script>

<style lang="scss">

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
    font-weight: 300;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* TRANSITIONS */

//Main Page Transition
.page-enter-to, .page-leave-from {
  opacity: 1;
}

.page-enter-from, .page-leave-to {
  opacity: 0;
}

.page-enter-active{
  transition: all 0.75s ease-out;
}

.page-leave-active{
  transition: all 0.75s ease-in;
}

//Objects with Exclusion
.exclusiontext-enter-to, .exclusiontext-leave-from {
  mix-blend-mode: exclusion;
  opacity: 1;
}

.exclusiontext-enter-from, .exclusiontext-leave-to {
  mix-blend-mode: normal;
  opacity: 0;
}

.exclusiontext-enter-active{
  transition: opacity 0.75s ease-out;
}

.exclusiontext-leave-active{
  transition: opacity 0.75s ease-in;
}

//Portfolio Transition
.pf-enter-to, .pf-leave-from {
  transform: translateX(0);
  opacity: 1;
}

.pf-enter-from, .pf-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.pf-enter-active{
  transition: all 0.5s ease-out;
}

.pf-leave-active{
  transition: all 0.5s ease-in;
}

//Portfolio Index Transition
.pfindex-enter-to, .pfindex-leave-from {
  transform: translateX(0);
  opacity: 1;
}

.pfindex-enter-from {
  transform: translateX(100%);
  opacity: 0;
}

.pfindex-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

.pfindex-enter-active{
  transition: all 0.5s ease-out;
}

.pfindex-leave-active{
  transition: all 0.5s ease-in;
}

/* ANIMATION */

@keyframes scrollText {
  from   { transform: translateX(0%); }
  to { transform: translateX(-50%); }
}

/* CORE */

::selection {
  background-color: $accent;
  color: $light;
  text-shadow: 0px 0px 0.25em $offblack;
}

html {

@include respond-to('small') {
    height: 100%;
  }
}

body {
  background-color: #140010;
}

#app {
  font-family: ballinger-condensed, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  color: #FFF;
}

#nav {
  position: absolute;
}

#wrapper {
  width: 100%;
  min-height: 100vh;
  display: block;
  z-index: 0;
}

.page-top {
  height: 60vh;
  padding-top: 58vh;

  h4 {
    font-size: 1.2em;
    margin: 0 auto;
    mix-blend-mode: exclusion;

    &::before {
      content: "//";
      font-size: 1.2em;
      margin-right: 0.25em;
    }
  }
}

.top-section {
    height: 5em;
}

.navDefault {
  opacity: 1;
  transform: translateX(0px);
  transition: all 0.5s;
}

.inActive {
  opacity: 0 !important;
  transform: translateX(50%);
  transition: all 0.5s;
}

p {
  text-align: left;
  font-size: 1.5em;
  letter-spacing: 0.01em;
  line-height: 1.3em;
  padding: 0 5px;
}

p.copy {
  margin-bottom: 1.5em;
  max-width: 80%;
  text-transform: none;
  letter-spacing: 0.03em;

  @include respond-to('small') {
    max-width: 100%;
  }
}

/* PORTFOLIO */

#portfolio-section {
    box-sizing: border-box;
    min-height: 50vh;
    padding-top: 4em;
    padding-bottom: 5em;
    margin: 0 auto;
    background-color: $transparent_offblack;
    box-shadow: 0px -3px 10px black;
    overflow: hidden;}
  
  #portfolio-main {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  }
  
  .portfolio-index, .portfolio-cont {
    max-width: $contWidth;
    margin: 0 auto;
  }
  
  .portfolio-index {
    padding: 0 1em;
  
    @include respond-to('small') {
      max-width: 90%;
    }
  }
  
  .portfolio-item {
    cursor: pointer;
    clear: both;
    text-decoration: none;
  
    @include respond-to('medium') {
      margin-bottom: 3em;
    }
  
  
    .portfolio-number {
      clear: both;
      font-size: 4em;
      text-align: left;
      color: $medium;
      margin-left: 1rem;
      margin-bottom: 0.25em;
  
      @include respond-to('medium') {
        font-size: 2em;
        margin-left: 0.25rem;
      }
  
      @include respond-to('small') {
        margin-left: 0;
      }
  
      &::before {
        content: "//";
        font-size: 1.25em;
        color: $medium;
        font-weight: 500;
        margin-right: 3px;
      }
    }
  
    .portfolio-name {
      font-size: 10vw;
      text-align: left;
      -webkit-text-stroke: 1px $light;
      color: transparent;
      margin-top: -2rem;
      transition: all 0.5s ease;
  
      @include respond-to('medium') {
        font-size: 12vw;
        -webkit-text-stroke: 0px $light;
        color: $light;
        margin-top: 0;
      }
  
      @include respond-to('small') {
        font-size: 13vw;
        font-weight: 500;
      }
    }
  
    .portfolio-skills {
      list-style-type: none;
  
      li {
        font-size: 2em;
        color: $medium;
        margin-left: 0.5em;
        float: left;
        opacity: 0;
        transition: all 0.5s ease;
        transform: translateX(-50%);
  
        @include respond-to('medium') {
          color: $light;
          margin-left: 0.25em;
        }
  
        @include respond-to('small') {
          font-size: 1em;
          margin-left: 0;
          margin-bottom: 5px;
        }
  
        &:not(:last-child) {
          
          &::after {
            content: "|";
            margin-left: 0.5em;
  
            @include respond-to('small') {
              content: "|";
              margin-left: 0.25em;
              margin-right: 0.25em;
            }
          }
        }
      }
    }

    @include respond-to('small') {
        .portfolio-skills {
          display: none;
        }
      }
  
    &:hover, &.active-item {
  
      .portfolio-name {
        -webkit-text-stroke: 0px $light;
        color: $light;
        transition: all 0.5s ease;
      }
  
      .portfolio-skills > li {
        opacity: 1;
        transition: all 0.5s ease;
        transform: translateX(0);
      }
  
      @for $i from 0 through 3 {
          .portfolio-skills li:nth-child(#{$i + 1}) {
            transition-delay: 0.2s * $i;
          }
      }
  
    }
  }
  
  .portfolio-piece {
    width: 100vw;
    min-height: 100vh;
    box-sizing: border-box;
  }
  
  .scroll-header {
    display: flex;
    position: relative;
    width: 100%;
    min-height: 11vw;
    margin: auto;
    overflow: hidden;
    z-index: 1;
    margin: 1em 0;
  
    @include respond-to('medium') {
      margin-top: 6em;
    }
  
    .inf-scroll {
  
          display: flex;
          position: absolute;
          top: 0;
          left: 0;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          height: 100%;
          white-space: nowrap;
          transition: all 1s ease;
  
          & > div {
              display: flex;
              animation: scrollText 20s infinite linear;
  
              @include respond-to('small') {
                animation: scrollText 8s infinite linear;
              }
          }
  
              h1 {
                  color: $light;
                  font-size: 10vw;
                  font-weight: 500;
                  margin-right: 0.3em;
  
                  &.add-kerning {
                    letter-spacing: 0.08em;
                  }
  
                  @include respond-to('small') {
                    font-size: 13vw;
                  }
  
                  span {
                      -webkit-text-stroke: 1px $light;
                      color: transparent;
                      font-weight: 500;
  
                      @include respond-to('small') {
                          -webkit-text-stroke: 0px $light;
                          color: $medium;
                      }
                  }
  
              }
      }
  }
  
  .portfolio-piece > h2 {
    font-size: 3vw;
    text-align: left;
    margin-bottom: 2em;
    padding: 0 1em;
  
    @include respond-to('medium') {
      font-size: 1.5em;
    }
  
    @include respond-to('small') {
      font-size: 1.1em;
    }
  }
  
  .portfolio-cont {
    box-sizing: border-box;
    width: 100%;
    max-width: $contWidth;
    margin-top: 3em;
    padding: 4em 8vw;
    background-color: $light;
  
    @include respond-to('small') {
      padding: 1em 0;
      width: 100%;
    }
  
    h3, p, figcaption {
      text-align: left;
    }
  
    figure.medium-img {
      transform: scale(1);
      transition: all 0.75s;
      width: 100%;
  
      & > img {
        width: 100%;
      }
  
      /*&:hover {
        transform: scale(1.2);
        transition: all .25s;
        z-index: 2;
      }*/
    }
  
    figure.large-img {
      transform: scale(1);
      transition: all 0.75s;
  
      & > img {
        width: 100%;
      }
  
      /*&:hover {
        transform: scale(1.1);
        transition: all .25s;
        z-index: 2;
  
        figcaption {
          font-size: 1.4em;
          color: $offblack;
          transition: all .25s;
        }
      }*/
    }
  
    p {
      text-transform: none;
      margin: 1em;
      padding: 1em;
      color: $offblack;
  
      @include respond-to('small') {
        font-size: 1.2em;
        margin: 0;
      }
    }
  
    figcaption {
      color: $medium;
      text-transform: none;
      font-size: 1.2em;
      transition: all .25s;
      background-color: $light;
      padding: 5px;
  
      @include respond-to('small') {
        margin-left: 0.5em;
      }
    }
  
    h3 {
      font-size: 5vw;
      margin-bottom: 1em;
      color: transparent;
      -webkit-text-stroke: 1px $light;
  
      @include respond-to('small') {
        font-size: 2em;
        color: $light;
        -webkit-text-stroke: 0px $light;
      }
  
      &::before {
        content: "//";
        font-size: 1.25em;
        color: $medium;
        font-weight: 500;
        margin-right: 3px;
      }
    }
  }
  
  .p-content-row-1 {
    margin: 4em 0;
  }
  
  .p-content-row-2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin: 4em 0;
  
    & > div {
      width: 48%;
    }
  
    @include respond-to('medium') {
      flex-flow: column nowrap;
      margin: 0;
  
      & > div {
        width: 100%;
        margin-bottom: 4em;
      }
    }
  }

</style>
