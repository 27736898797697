import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "portfolio" */ "../views/Portfolio.vue"),
    children: [
      {
        path: "",
        name: "Index",
        component: () =>
          import(/* webpackChunkName: "index" */ "../components/PortfolioIndex.vue"),
        //meta: { transition: "pfindex" },
      },
      { 
        path: "logos",
        name: "Logos",
        component: () =>
          import(/* webpackChunkName: "logos" */ "../components/PortfolioLogo.vue"),
        meta: { portfolioPath: true, transition: "pfindex" },
      },
      { 
        path: "etaerio",
        name: "Etaerio",
        component: () =>
          import(/* webpackChunkName: "etaerio" */ "../components/PortfolioEtaerio.vue"),
        meta: { portfolioPath: true, transition: "pfindex" },
      },
      { 
        path: "full-plate",
        name: "FullPlate",
        component: () =>
          import(/* webpackChunkName: "fullplate" */ "../components/PortfolioFullPlate.vue"),
        meta: { portfolioPath: true, transition: "pfindex" },
      },
      { 
        path: "anchor-point",
        name: "AnchorPoint",
        component: () =>
          import(/* webpackChunkName: "anchorpoint" */ "../components/PortfolioAnchorPoint.vue"),
        meta: { portfolioPath: true, transition: "pfindex" },
      },
      { 
        path: "witcher",
        name: "TheWitcher",
        component: () =>
          import(/* webpackChunkName: "thewitcher" */ "../components/PortfolioWitcher.vue"),
        meta: { portfolioPath: true, transition: "pfindex" },
      },
      { 
        path: "all-these-lights",
        name: "AllTheseLights",
        component: () =>
          import(/* webpackChunkName: "alltheselights" */ "../components/PortfolioATL.vue"),
        meta: { portfolioPath: true, transition: "pfindex" },
      },
      { 
        path: "inspirational-creatives",
        name: "InspirationalCreatives",
        component: () =>
          import(/* webpackChunkName: "inspirational" */ "../components/PortfolioInspirational.vue"),
        meta: { portfolioPath: true, transition: "pfindex" },
      },
      { 
        path: "good-time-charlies",
        name: "GoodTimeCharlies",
        component: () =>
          import(/* webpackChunkName: "goodtimecharlies" */ "../components/PortfolioCharlies.vue"),
        meta: { portfolioPath: true, transition: "pfindex" },
      },
    ]
  },
  {
    path: "/case-study",
    name: "CaseStudy",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "casestudy" */ "../views/CaseStudyGTC.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from) {
    if ((to.matched.some(record => record.meta.portfolioPath)) || ((from.matched.some(record => record.meta.portfolioPath)) && (to.name == "Index"))) {
      let offset = document.querySelector('#portfolio-main').offsetTop;
      return new Promise((resolve) => {
        setTimeout(() => {
            resolve({ top: offset })
        }, 500)
      })
      } else {
      return {
        top: 0,
        behavior: 'smooth'
      }
    }
  },
});

export default router;
