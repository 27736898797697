<template>
    <div id="anim" ref="anim"></div>
</template>

<script>
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';

export default {
    name: "Animation",
    mounted() {
        this.$nextTick(function() {
            this.initAnimation();
        })
    },
    created() {
        window.addEventListener('resize', this.resize);
        window.addEventListener('mousemove', this.onMouseMove);
    },
    unmounted() {
        window.removeEventListener('resize', this.resize);
    },
    methods: {
        initAnimation() {
            this.heightBase = 0;
            this.spacing = 14;

            this.scene = new THREE.Scene();
            this.scene.fog = new THREE.FogExp2( 0x0d0a0b, 0.025, 1);

            //Skybox
            this.loader = new THREE.CubeTextureLoader();
            this.loader.setCrossOrigin('anonymous');

            const texture = this.loader.load([
            "../assets/skybox/v2_khskybox_left.webp",
            "../assets/skybox/v2_khskybox_right.webp",
            "../assets/skybox/v2_khskybox_top.webp",
            "../assets/skybox/v2_khskybox_bottom.webp",
            "/v2_khskybox_front.png",
            "./v2_khskybox_front.png",
            ]);
            this.scene.background = texture;

            this.camera = new THREE.PerspectiveCamera(
                65,
                window.innerWidth / window.innerHeight,
                0.1,
                1000
            );
            this.renderer = new THREE.WebGLRenderer({ antialias: true });
            this.renderer.setSize(window.innerWidth, window.innerHeight);
            this.renderer.setClearColor('#0d0a0b')
            this.renderer.shadowMap.enabled = true;
            this.renderer.shadowMap.renderSingleSided = false;
            this.$refs.anim.appendChild(this.renderer.domElement);

            //Light
            var amblight = new THREE.DirectionalLight(0xeae6e6, 0.25);
            this.scene.add(amblight);

            this.light = new THREE.PointLight(0xeae6e6, 15, 2);
            this.light.position.y = 3;
            this.scene.add(this.light);

            const pointLightHelper = new THREE.PointLightHelper( this.light, 1 );
            this.scene.add( pointLightHelper );

            //Load model
            const dracoLoader = new DRACOLoader();
	        dracoLoader.setDecoderPath('three/examples/js/libs/draco/gltf/');

            const loader = new GLTFLoader();
            loader.setDRACOLoader(dracoLoader);
            var sceneContext = this.scene;
            var spacingContext = this.spacing;
            var heightContext = this.heightBase;
            var scaleConst = 1;
        
                for(let x=0; x<15; x++) {
                /*let geometry = new THREE.BoxGeometry();
                let material = new THREE.MeshBasicMaterial({ color: 0xFFFFFF })
                let cube = new THREE.Mesh(geometry, material);
                cube.position.y = 1.5;
                cube.position.z = -([x]*4);
                this.scene.add(cube);*/
                    loader.load('./monolith_v4.glb', function(gltf){
                    let model = gltf.scene;
                    model.name = "monolith"+[x];
                    model.children[0].scale.set(scaleConst, scaleConst, scaleConst);
                    model.position.x = 2.5;
                    model.position.y = 0.75;
                    model.position.z = -([x]*spacingContext);
                    sceneContext.add(model);

                    model.traverse(function (object) {
                        if (object.isMesh) object.material.flatShading = false;
                        if (object.isMesh) object.castShadow = true;
                        if (object.isMesh) object.receiveShadow = true;
                    })

                    let geometry = new THREE.PlaneGeometry(100, spacingContext, 64, 64);
                    let wavesTexture = new THREE.TextureLoader().load(
                    './displacementWaves.jpg'
                    )
                    let material = new THREE.MeshPhongMaterial({
                        color: 0x0d0a0b,
                        reflectivity: 0,
                        shininess: 50,
                        displacementMap: wavesTexture,
                        displacementScale: 0.5,
                        displacementBias: -0.2,
                        wireframe: false,
                    })
                    
                    let surface = new THREE.Mesh(geometry, material);
                    surface.castShadow = true;
                    surface.receiveShadow = true;
                    surface.position.y = heightContext - 1;
                    surface.position.z = -([x]*spacingContext);
                    surface.rotation.x = Math.PI / -2;
                    sceneContext.add(surface);

                    let light = new THREE.PointLight(0xffeeee, 2, 10, 2);
                    light.position.set(0, -([x]*spacingContext), heightContext + 5);
                    light.castShadow = true;
                    sceneContext.add(light);
                })
            }

            this.camera.position.z = 0;
            this.animate();
        },
        animate() {
            requestAnimationFrame(this.animate);
            this.camera.position.z -= 0.1;
                if (this.camera.position.z < -(this.spacing)){
                    this.camera.position.z = 0;
                    //this.light.position.z = -10;
                };
            this.light.position.z = (this.camera.position.z)+2;
            //this.cube.rotation.x += 0.1;
            //this.cube.rotation.y += 0.1;
            this.renderer.render(this.scene, this.camera);
        },
        onMouseMove(event) {
            if (window.innerWidth > 992) {
                this.camera.position.x = this.clamp(((event.clientX - window.innerWidth / 2) / 3200), -0.22, 0.22);
                this.camera.rotation.y = this.clamp((-(event.clientX - window.innerWidth / 2) / 20000), -0.08, 0.08);
                this.camera.position.y = (((event.clientY - window.innerWidth / 2) / 3200)*-1) + this.heightBase;
                this.camera.rotation.x = this.clamp((-(event.clientY - window.innerWidth / 2) / 40000) + 0.02, -0.02, 0.06);
            }
        },
        resize() {
            this.camera.aspect = window.innerWidth/window.innerHeight;
            this.camera.updateProjectionMatrix();
            this.renderer.setSize(window.innerWidth, window.innerHeight);
        },
        clamp(num, min, max) {
            return Math.min(Math.max(num, min), max);
        },
    },

}
</script>

<style lang="scss">

canvas {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -99;
}

</style>