<template>
  <div id="home-content">
  <div class="menu">
    <div class="logo-subtle" aria-hidden="true">
      <img alt="kyler herrera logo" src="../assets/images/logo_white.svg" />
    </div>

    <h1>Kyler Herrera</h1>
    <ul class="nav">
      <li><router-link to="/about">About</router-link></li>
      <li><router-link to="/portfolio">Portfolio</router-link></li>
      <li><router-link to="/case-study">Case Study</router-link></li>
      <li><router-link to="/contact">Contact</router-link></li>
    </ul>
  </div>
    <footerInfo class="lock-bottom" />
  </div>
</template>

<script>
import FooterInfo from "../components/FooterInfo.vue"

export default {
  name: "Home",
  components: {
    FooterInfo,
  },
};
</script>

<style lang="scss">

#home-content {
  width: 100vw;
  height: 100vh;
  background-color: $transparent_offblack;
}

.menu {
    width: 35%;
    margin: auto;
    align-content: center;
    padding-top: 40vh;
    max-height: 90vh;

    @include respond-to('medium') {
        width: 80%;
    }

    @include respond-to('small') {
        padding-top: 20vh;
    }

  h1 {
    text-align: center;
    color: $light;
    padding-top: 1em;
    padding-bottom: 3em;
    font-weight: 300;
    font-size: 1.2em;
    letter-spacing: 5px;
    text-shadow: 0px 0px 0.25em $offblack;
  }

  h3 {
    text-align: center;
    padding-top: 2em;
  }
}

.logo-subtle {
	margin: auto;
	width: 10%;
  min-width: 6em;
}

.nav {
    margin: 0 auto;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;

    @include respond-to('small') {
      flex-direction: column;
    }

  li {
    text-align: center;
    flex: 1;
    height: 1.3em;

    @include respond-to('small') {
      margin-bottom: 1em;
    }

    a {
      text-shadow: 0px 0px 0.25em $offblack;
      font-size: 1.1em;
      text-decoration: none;
      color: $light;
      cursor: pointer;
      transition: all 0.1s;

      &::before {
          content: "{ ";
          margin-right: 5px;
          font-size: 1.2em;
          opacity: 0;
        }

      &::after {
        content: " }";
        margin-left: 5px;
        font-size: 1.2em;
        opacity: 0;
      }

      @include respond-to('medium') {
        font-size: 1.25em;
        color: $light;
      }

      @include respond-to('small') {
        font-size: 1.6em;
      }

      &:hover {
        font-size: 1.25em;
        color: $light;
        transition: all 0.25s;

        @include respond-to('medium') {
        font-size: 1.25em;
        color: $light;
        }

        @include respond-to('small') {
        font-size: 1.6em;
        }

        &::before {
          transform: scale(1);
          opacity: 1;
        }

        &::after {
          transform: scale(1);
          opacity: 1;
        }
      }
    }
  }

}

</style>
