<template>
    <div id="footer">
        <div class="footer-left">
            <p>Copyright &copy; 2015-{{ this.getYear }} Kyler Herrera | All Rights Reserved</p>
        </div>
        <div class="footer-right">
            <p>Version 3.0.0</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "FooterInfo",
    computed: {
        getYear() {
            return new Date().getFullYear();
        }
    },
}
</script>


<style lang="scss">

#footer {
    height: 2em;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    @include respond-to('small') {
        bottom:40px;
        flex-flow: column nowrap;
    }

    div p {
        color: $medium;
        font-size: 1em;
        padding: 0px 1em;

        @include respond-to('small') {
        font-size: 0.8em;
        margin-bottom: 1em;
        }
    }
}

.lock-bottom {
    position: absolute;
    bottom: 0px;
    left: 0px;
}

.footer-back {
    background-color: $transparent_offblack;
}

</style>